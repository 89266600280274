// Import our custom CSS
// import '../sass/app.scss'

// // Import all of Bootstrap's JS
// import * as bootstrap from 'bootstrap'

// import Alert from 'bootstrap/js/dist/alert'

// // or, specify which plugins you need:
// import { Tooltip, Toast, Popover } from 'bootstrap'


if (module.hot) {
    module.hot.accept();
  }